import NotificationView from "modules/notifications/components/NotificationView"
import { Notification, NotificationAudit } from "modules/notifications/types"
import React, { useEffect, useState } from "react"
import { acceptNotification, getNotifications } from "../service/api"
import { useTranslation } from "react-i18next"
import { handleToast } from "utils/messages"
import useUserFromToken from "hooks/useUserFromToken"
import Checkbox from "components/Checkbox/Checkbox"
import { useDispatch } from "react-redux"
import { updateUser } from "../actions"

const NewUserNotifications = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isSubscriptionAggree, setIsSubscriptionAggree] = useState(false)
  const {
    isSubscriptionAggree: isSubscriptionAggreeGet,
    name,
    id
  } = useUserFromToken()

  const [notificationsAud, setNotificationsAud] = useState<NotificationAudit[]>([])

  const { t } = useTranslation()

  useEffect(() => {
    getNotifications().then((response) => {
      const { data } = response
      if (data) {
        const unacceptedNotifications = data.filter(na => !na.acceptAt)
        if (unacceptedNotifications.length > 0) {
          setNotificationsAud(unacceptedNotifications)
          setIsOpen(true)
        }
      }
    })
    setIsSubscriptionAggree(isSubscriptionAggreeGet || false)
  }, [])

  const onClickAcceptNotification = (id: number) => {
    acceptNotification({id}).then(res => {
      const { data, errors } = res
      console.log(data)
      if (errors) {
        handleToast(errors, "error")
      } else {
        handleToast(["Notification Acccepted"], "info")

        const unacceptedNotifications = notificationsAud.filter(na => na.id !== id)
        if (unacceptedNotifications.length > 0) {
          setNotificationsAud([...unacceptedNotifications])
        } else {
          setNotificationsAud([])
          setIsOpen(false)
        }
      }
    })
  }

  const updateUserSubscriptionAggree = (subscriptionAggree: boolean) => {
    setIsSubscriptionAggree(subscriptionAggree)
    dispatch(updateUser({ id, name, mailAggree: subscriptionAggree }))
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      {notificationsAud.map(na =>(
        <div className="modal-open">
        <div key={na.id}
          className={`srs-modal modal display-block w-100 p-0 m-0 border-0 fade ${
            isOpen && "d-block show"
          }`}
          style={{ outline: "none" }}
        >
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <button
                type="button"
                className="srs-modal-close position-absolute close"
                onClick={handleModalClose}
              >
                <span>&times;</span>
              </button>
            <div className="modal-header">
                <h3 className="modal-title">
                  <strong>{t("Notification")}</strong>
                </h3>
              </div>
              <div className="modal-body">
                {na.notification && (
                  <NotificationView notification={na.notification} ></NotificationView>
                )}
                {na.notification?.subscriptionBtn && (
                  <div>
                    <Checkbox
                      label={
                        <span>
                          {t("I consent to the storage and use of my email address for the purpose of receiving newsletters and other emails concerning RecoTrace, Polyrec and/or VinylPlus.")}
                        </span>
                      }
                      name="mailAggree"
                      value={isSubscriptionAggree}
                      handleOnChange={(e) => updateUserSubscriptionAggree( e.target.checked)}
                    />
                  </div>
                )}
              </div>
              <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary rounded-bottom-left"
                onClick={handleModalClose}
              >
                {t("Cancel")}
              </button>
                <button
                  type="submit"
                  className="btn btn-primary rounded-bottom-right"
                  onClick={() => onClickAcceptNotification(na.id)}
                >
                  {t("I have read this information")}
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
      ))}
    </>
  )
}
export default NewUserNotifications