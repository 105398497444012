import { useFormik } from "formik"
import React, { useCallback, useEffect, useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import { useDispatch } from "react-redux"
import ReactTooltip from "react-tooltip"
import * as Yup from "yup"

import Checkbox from "components/Checkbox/Checkbox"
import Input from "components/Input/Input"
import Select, { mapToOptions, SelectValue } from "components/Select/Select"

import { handleToast } from "utils/messages"
import { history } from "utils/routes"

import useStateGetter from "hooks/useStateGetter"
import useUserFromToken from "hooks/useUserFromToken"

import { fetchCountries } from "../../../countries/actions"
import { fetchMaterialGroups } from "../../../materials/actions"
import { MaterialGroup } from "../../../materials/service/api"
import { ACTIVITY_LIST } from "../../constants"
import { getMosaList, getParentCompanies, postPlant, putCompany } from "../../service/api"
import { Company, CompanyRole, Mosa } from "../../types"

interface Props {
  initialCompany?: Company
  submitHandler?: (company: any) => void
  onSuccessHandler?: () => void
  onErrorHandler?: () => void
  isCompany?: boolean
  selectCompany?: boolean
  selectedCompany?: Company
}

const CompanyForm: React.FC<Props> = ({
  initialCompany,
  submitHandler,
  onSuccessHandler,
  onErrorHandler,
  isCompany = false,
  selectCompany = false,
  selectedCompany = null,
}) => {
  const CompanyFormValidationShema = Yup.object().shape({
    selectedCountry: Yup.object().typeError("Country is required."),
    vatNumber: Yup.string().required("VAT number is required"),
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    // ceoName: Yup.string().required('Name of CEO is required.'),
    ceoEmail: Yup.string().nullable().email("Invalid email"),
    // .required('Email of CEO is required.'),
    companyRole: Yup.object().typeError("Role is required."),
    // agreeTerms: Yup.bool().when("isCompany", {
    //   is: true,
    //   then: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
    // }),
  })

  const SelectCompanyFormValidationShema = Yup.object().shape({
    selectedCompany: Yup.object().typeError("Parent company is required."),
    selectedCountry: Yup.object().typeError("Country is required."),
    vatNumber: Yup.string().required("VAT number is required"),
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    // ceoName: Yup.string().required('Name of CEO is required.'),
    ceoEmail: Yup.string().nullable().email("Invalid email"),
    // .required('Email of CEO is required.'),
    companyRole: Yup.object().typeError("Role is required."),
    // agreeTerms: Yup.bool().when("isCompany", {
    //   is: true,
    //   then: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
    // }),
  })

  const { id } = initialCompany ?? { id: null }

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const materialGroups =
    useStateGetter<MaterialGroup[]>(["material", "materialGroups"]) ?? []

  const countries =
    useStateGetter<{ id: string; name: string }[]>(["country", "countries"]) ??
    []
  const COUNTRIES = countries.map((countrie) => ({
    value: parseInt(countrie.id, 0),
    label: countrie.name,
  }))

  const [parentCompanies, setParentCompanies] = useState<Company[]>([])
  const fetchParentCompanies = () => {
    getParentCompanies().then((response) => {
      if (response.data) {
        setParentCompanies(response.data)
      }
    })
  }
  const COMPANIES = parentCompanies.map((c) => ({
    value: c.id || 0,
    label: c.name,
  }))

  useEffect(() => {
    if (selectCompany) {
      fetchParentCompanies()
    }
  }, [selectCompany])

  const [mosaList, setMosaList] = useState<Mosa[]>([])
  const [ufmeChecked, setUfmeChecked] = useState(false)
  const [snepChecked, setSnepChecked] = useState(false)

  const checkUfmeChecked = (selectedOptions: any[]) => {
    if (selectedOptions.find((so) => so.label === 'UFME')) {
      setUfmeChecked(true)
    } else {
      setUfmeChecked(false)
    }
  }

  const checkSnepChecked = (selectedOptions: any[]) => {
    if (selectedOptions.find((so) => so.label === 'SNEP')) {
      setSnepChecked(true)
    } else {
      setSnepChecked(false)
    }
  }

  useEffect(() => {
    getMosaList().then(res => {
      if (res.data){
        console.log(res.data)
        setMosaList(res.data)
      }
    })
  }, [])

  const getSelectedMultiValues = (values: Array<{ value: number }>) =>
    values.map((value) => value.value)

  const handleOnSubmit = useCallback(
    async (
      {
        name,
        selectedCountry,
        selectedCompany,
        address,
        vatNumber,
        ceoName,
        ceoEmail,
        capacities,
        website,
        allowDisplayOnWebsite,
        companyPolymer,
        companyRole,
        companyMosa,
        sharedDataWithMosa,
        sharedUfmeWithEppa,
        sharedSnepWithEppa,
      }: any,
      { resetForm }: { resetForm: () => void },
    ) => {
      if (!loading) {
        setLoading(true)
        let cachedErrors
        if (id) {
          const { errors } = await putCompany({
            id,
            name,
            country: selectedCountry.value,
            address,
            vatNumber,
            ceoName,
            ceoEmail,
            website,
            allowDisplayOnWebsite,
            capacities: parseInt(capacities, 10),
            polymers: getSelectedMultiValues(companyPolymer),
            mosa: getSelectedMultiValues(companyMosa),
            roleId: companyRole.value,
            sharedDataWithMosa,            
            sharedUfmeWithEppa,
            sharedSnepWithEppa,
          })
          cachedErrors = errors
        } else {
          const { errors } = await postPlant({
            name,
            country: selectedCountry.value,
            parent: selectedCompany ? selectedCompany.value : null,
            address,
            vatNumber,
            ceoName,
            ceoEmail,
            capacities: parseInt(capacities, 10),
            polymers: getSelectedMultiValues(companyPolymer),
            mosa: getSelectedMultiValues(companyMosa),
            roleId: companyRole.value,
          })
          cachedErrors = errors
        }
        if (cachedErrors) {
          handleToast(cachedErrors, "error")
          if (onErrorHandler) {
            onErrorHandler()
          }
          setLoading(false)
        } else {
          handleToast(["Company updated"])
          // resetForm();
          if (onSuccessHandler) {
            onSuccessHandler()
          } else {
            setLoading(false)
          }
        }
      }
    },
    [id, loading, onErrorHandler, onSuccessHandler],
  )

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      selectedCountry: null,
      selectedCompany: null,
      vatNumber: "",
      ceoName: "",
      ceoEmail: "",
      capacities: 0,
      website: "",
      allowDisplayOnWebsite: false,
      companyRole: null,
      companyPolymer: [],
      companyMosa: [],
      sharedDataWithMosa: false,      
      sharedUfmeWithEppa: false,
      sharedSnepWithEppa: false,
      // agreeTerms: false,
    },
    validationSchema: selectCompany
      ? SelectCompanyFormValidationShema
      : CompanyFormValidationShema,
    onSubmit: submitHandler || handleOnSubmit,
  })

  const { values, handleChange, touched, errors, setFieldValue, handleSubmit } =
    formik

  const onSelectChange = (selectedOption: SelectValue) => {
    setFieldValue("selectedCountry", selectedOption)
  }

  const onSelectCompanyChange = (selectedOption: SelectValue) => {
    setFieldValue("selectedCompany", selectedOption)
  }

  const onAllowDisplayChange = (newValue: boolean) => {
    setFieldValue("allowDisplayOnWebsite", newValue)
  }

  const onSelectChangeRole = (selectedOption: SelectValue) => {
    setFieldValue("companyRole", selectedOption)
  }

  const onSelectChangePolymer = (selectedOption: SelectValue) => {
    setFieldValue("companyPolymer", selectedOption)
  }

  const onSelectChangeMosa = (selectedOption: SelectValue) => {
    if (selectedOption && Array.isArray(selectedOption)){
      console.log(selectedOption)
      checkUfmeChecked(selectedOption)
      checkSnepChecked(selectedOption)
    }
    setFieldValue("companyMosa", selectedOption)
  }

  const onAllowSharedDataWithMosa = (newValue: boolean) => {
    setFieldValue("sharedDataWithMosa", newValue)
  }

  const onAllowSharedUfmeWithEppa = (newValue: boolean) => {
    setFieldValue("sharedUfmeWithEppa", newValue)
  }
  const onAllowSharedSnepWithEppa = (newValue: boolean) => {
    setFieldValue("sharedSnepWithEppa", newValue)
  }

  useEffect(() => {
    if (initialCompany) {
      setFieldValue("name", initialCompany.name)
      setFieldValue("address", initialCompany.address)
      if (initialCompany.country) {
        setFieldValue("selectedCountry", {
          label: initialCompany.country.name,
          value: initialCompany.country.id,
        })
      }
      setFieldValue("vatNumber", initialCompany.vatNumber)
      setFieldValue("ceoName", initialCompany.ceoName)
      setFieldValue("ceoEmail", initialCompany.ceoEmail)
      setFieldValue("capacities", initialCompany.capacities)
      setFieldValue("website", initialCompany.website)
      setFieldValue(
        "allowDisplayOnWebsite",
        initialCompany.allowDisplayOnWebsite,
      )
      setFieldValue("sharedDataWithMosa", initialCompany.sharedDataWithMosa)
      setFieldValue("sharedUfmeWithEppa", initialCompany.sharedUfmeWithEppa)
      setFieldValue("sharedSnepWithEppa", initialCompany.sharedSnepWithEppa)

      if (isCompany) {
        const initialCompanyMosa = initialCompany.mosa?.map((mosa) => ({
          value: mosa.id,
          label: mosa.name,
        }))
        if (initialCompanyMosa){
          checkUfmeChecked(initialCompanyMosa)
          checkSnepChecked(initialCompanyMosa)
        }
        setFieldValue("companyMosa", initialCompanyMosa)
      }

      const initialCompanyPolymer = initialCompany.polymers?.map((polymer) => ({
        value: polymer.id,
        label: polymer.name,
      }))
      setFieldValue("companyPolymer", initialCompanyPolymer)

      const companyRole = ACTIVITY_LIST.find(
        (x) => x.value === initialCompany.roleId,
      )
      setFieldValue("companyRole", companyRole)
    }
  }, [initialCompany, isCompany, setFieldValue])

  useEffect(() => {
    if (selectedCompany) {
      setFieldValue("selectedCompany", {
        value: selectedCompany.id,
        label: selectedCompany.name,
      })
    }
  }, [selectedCompany, setFieldValue])

  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchMaterialGroups())
  }, [dispatch])

  const { t } = useTranslation()
  const { role } = useUserFromToken()

  const allowedActivityOptions = useMemo(() => {
    // if (isCompany)
    //   return ACTIVITY_LIST.filter((x) => x.value !== CompanyRole.CONVERTER)

    if (role == CompanyRole.RECYCLER)
      return ACTIVITY_LIST.filter((x) => x.value !== CompanyRole.CONVERTER)
    if (role == CompanyRole.CONVERTER)
      return ACTIVITY_LIST.filter((x) => x.value !== CompanyRole.RECYCLER)
    if (role == CompanyRole.RECYCLER_AND_CONVERTER) return ACTIVITY_LIST

    return ACTIVITY_LIST
  }, [role, isCompany])

  return (
    <>
      <form onSubmit={handleSubmit}>
        {selectCompany ? (
          <Select
            name="selectedCompany"
            label={`${t("Parent company")} (*)`}
            options={COMPANIES}
            value={values.selectedCompany}
            handleOnChange={onSelectCompanyChange}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            error={touched.selectedCompany && errors.selectedCompany}
          />
        ) : null}
        <Input
          label={`${t("Name")} (*)`}
          name="name"
          value={values.name}
          handleOnChange={handleChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.name && errors.name}
        />
        <Select
          name="selectedCountry"
          label={`${t("Country")} (*)`}
          options={COUNTRIES}
          value={values.selectedCountry}
          handleOnChange={onSelectChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.selectedCountry && errors.selectedCountry}
        />
        <Select
          name="companyRole"
          label={`${t("Activity")} (*)`}
          options={allowedActivityOptions}
          value={values.companyRole}
          handleOnChange={onSelectChangeRole}
          inputWrapperClassName="col-sm-8"
          wrapperClassName="align-items-center"
          labelClassName="col-sm-4"
          error={touched.companyRole && errors.companyRole}
        />
        <div className="srs-select form-group row align-items-center">
          <label className="col-sm-4 col-form-label">{`${t(
            "Polymer(s) processed",
          )} (*)`}</label>
          <div className="multi-select-wrapper col-sm-8">
            <MultiSelect
              options={mapToOptions(materialGroups)}
              value={values.companyPolymer}
              onChange={onSelectChangePolymer}
              labelledBy="companyPolymer"
              disableSearch
            />
            {touched.companyPolymer && values.companyPolymer.length === 0 ? (
              <small className="form-text text-danger">
                Field of Polymer(s) processed is required.
              </small>
            ) : null}
          </div>
        </div>
        <Input
          name="address"
          value={values.address}
          label={`${t("Address")} (*)`}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          handleOnChange={handleChange}
          error={touched.address && errors.address}
        />
        <Input
          name="vatNumber"
          value={values.vatNumber}
          label={`${t("Vat number")} (*)`}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          handleOnChange={handleChange}
          error={touched.vatNumber && errors.vatNumber}
        />
        <span data-tip="For communication purposes only.">
          <Input
            name="ceoName"
            value={values.ceoName}
            label={`${t("CEO name")} (i)`}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            handleOnChange={handleChange}
            error={touched.ceoName && errors.ceoName}
          />
        </span>

        <span data-tip="For communication purposes only.">
          <Input
            name="ceoEmail"
            value={values.ceoEmail}
            label={`${t("CEO email")} (i)`}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
            handleOnChange={handleChange}
            error={touched.ceoEmail && errors.ceoEmail}
          />
        </span>

        {isCompany ? (
          <>
            <Input
              label={t("Company Website")}
              name="website"
              value={values.website}
              handleOnChange={formik.handleChange}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              error={touched.website && errors.website}
            />
            <Checkbox
              label={t(
                "Company name and website can appear on the PolyREC and Recovinyl websites",
              )}
              name="allowDisplayOnWebsite"
              value={values.allowDisplayOnWebsite}
              handleOnChange={() =>
                onAllowDisplayChange(!values.allowDisplayOnWebsite)
              }
            />
          </>
        ) : null}
        {!isCompany ? (
          <span data-tip="Optionally add your production capacity to be able to visually track your own performance. This data will not be shared and is only visible for you.">
            <Input
              name="capacities"
              value={values.capacities}
              label={`${t("Capacities")} (i)`}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
              handleOnChange={handleChange}
            />
          </span>
        ) : null}
        <ReactTooltip />
        {/* <Checkbox
          name="isInput"
          value={values.isInput}
          label={t('Is input')}
          checkboxWrapperClassName="mb-0 mr-0"
          handleOnChange={() => setFieldValue('isInput', !values.isInput)}
        /> */}
        {isCompany ? (
          <div className="srs-select form-group row align-items-center">
            <label
              htmlFor="companyMosa"
              className="col-sm-4 col-form-label text-align-right"
            >
              {`${t(
                "If you are a member of the following sector associations, please select",
              )} `}
              <span
                className="text-primary"
                data-tip="These sector associations are not a part of PolyREC"
              >
                (i)
              </span>
            </label>
            <ReactTooltip />
            <div className="multi-select-wrapper col-sm-8">
              <MultiSelect
                options={mapToOptions(mosaList)}
                value={values.companyMosa}
                onChange={onSelectChangeMosa}
                labelledBy="companyMosa"
                disableSearch
              />
            </div>
            <div className="col-sm-4" />
            <div className="col-sm-8">
              <Checkbox
                label={t(
                  "All data can be shared with the selected sector association(s)",
                )}
                name="sharedDataWithMosa"
                value={values.sharedDataWithMosa}
                handleOnChange={() =>
                  onAllowSharedDataWithMosa(!values.sharedDataWithMosa)
                }
              />
              {ufmeChecked && (
                <Checkbox
                label={t(
                  "By selecting you agree that UFME shares his data with EPPA",
                )}
                name="sharedUfmeWithEppa"
                value={values.sharedUfmeWithEppa}
                handleOnChange={() =>
                  onAllowSharedUfmeWithEppa(!values.sharedUfmeWithEppa)
                }
                />
              )}
              {snepChecked && (
                <Checkbox
                  label={t(
                    "By selecting you agree that SNEP shares its data with EPPA",
                  )}
                  name="sharedSnepWithEppa"
                  value={values.sharedSnepWithEppa}
                  handleOnChange={() =>
                    onAllowSharedSnepWithEppa(!values.sharedSnepWithEppa)
                  }
                />
              )}
            </div>
          </div>
        ) : null}
        <div className="d-flex justify-content-between mt-5">
          <button
            type="button"
            className="btn btn-outline-primary rounded-bottom-left"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-arrow-left mr-2" /> {t("Back")}
          </button>

          <div className="text-right">
            <button
              type="submit"
              className="btn btn-success rounded-bottom-right"
              disabled={loading}
            >
              {id ? t("Update") : t("Submit")}
              <i className="fas fa-arrow-right ml-3" />
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default CompanyForm
