import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import Input from "../../../components/Input/Input"
import useStateGetter from "../../../hooks/useStateGetter"
import useUserFromToken from "../../../hooks/useUserFromToken"
import { handleToast } from "../../../utils/messages"
import CompanyForm from "../../companies/components/CompanyForm/CompanyForm"
import { getCompany, getCompanyPlants } from "../../companies/service/api"
import { Company } from "../../companies/types"
import { fetchUser, updateUser } from "../actions"
import { User } from "../types"
import ParentUserAccount from "./ParentUserAccount"
import PlantsList from "./PlantsList"
import UserAccountFormValidationShema from "./UserAccountFormValidationShema"
import Checkbox from "components/Checkbox/Checkbox"

const UserAccount2 = () => {
  const user = useStateGetter<User>(["user", "user"])
  const { isRrr, isAuditor } = useUserFromToken()
  const isParentRoles = isRrr || isAuditor // isAdmin || isRrr || isAuditor;
  const dispatch = useDispatch()
  const [tab, setTab] = useState("company")
  const [isHiddenChangePassword, setIsHiddenChangePassword] = useState("d-none")

  const [company, setCompany] = useState<Company>()
  const [companyPlants, setCompanyPlants] = useState<Company[]>([])

  useEffect(() => {
    getCompanyPlants().then(({ data }) => {
      if (data) {
        setCompanyPlants(data)
      }
    })
  }, [setCompanyPlants])

  useEffect(() => {
    getCompany().then(({ data }) => {
      if (data) {
        setCompany(data)
      }
    })
  }, [setCompany])

  const handleOnSubmit = ({
    name,
    email,
    password,
    mailAggree,
  }: {
    name: string
    email: string
    password: string
    mailAggree: boolean
  }) => {
    if (user?.id) {
      dispatch(updateUser({ id: parseInt(user.id, 10), name, email, password, mailAggree }))
      handleToast(["Your account is updated successfully"])
    }
  }

  const handleOnParentUserAccountNofify = () => {
    dispatch(fetchUser())
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      mailAggree: false,
    },
    validationSchema: UserAccountFormValidationShema,
    onSubmit: handleOnSubmit,
  })
  const { values, touched, errors, handleChange, setFieldValue, handleSubmit } =
    formik

  useEffect(() => {
    dispatch(fetchUser())
  }, [dispatch])

  useEffect(() => {
    if (user) {
      setFieldValue("name", user.name)
      setFieldValue("email", user.email)
      setFieldValue("password", user.password)
      setFieldValue("mailAggree", user.mailAggree)
    }
  }, [setFieldValue, user])

  const { t } = useTranslation()

  const changeTab = (
    e: React.MouseEvent<HTMLAnchorElement>,
    tabName: string,
  ) => {
    e.preventDefault()
    setTab(tabName)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h1 className="mb-5">
          {t("Profile")} {user?.email}
        </h1>
        <Input
          label={t("Username")}
          name="name"
          value={values.name}
          handleOnChange={handleChange}
          inputWrapperClassName="col-sm-8"
          labelClassName="col-sm-4"
          error={touched.name && errors.name}
        />
        {user && (
          <Input
            label={t("Email address")}
            name="email"
            value={values.email}
            handleOnChange={handleChange}
            inputWrapperClassName="col-sm-8"
            labelClassName="col-sm-4"
          />
        )}
        <div className="form-group row align-items-center">
          <div className="col-sm-4" />
          <div className="col-sm-8">
            <button
              type="button"
              className="btn btn-warning btn-sm text-left"
              style={{ width: "100%" }}
              onClick={() => setIsHiddenChangePassword("d-block")}
            >
              <i className="fas fa-pen btn-icon mr-1" />{" "}
              {t("Click to change password")}
            </button>
          </div>
        </div>
        {user && (
          <div className={isHiddenChangePassword}>
            <Input
              label={t("New Password")}
              name="password"
              value={values.password}
              handleOnChange={handleChange}
              inputWrapperClassName="col-sm-8"
              labelClassName="col-sm-4"
            />
          </div>
        )}
        <Checkbox
          label={
            <span>
              {t("I consent to the storage and use of my email address for the purpose of receiving newsletters and other emails concerning RecoTrace, Polyrec and/or VinylPlus.")}
            </span>
          }
          name="mailAggree"
          value={values.mailAggree}
          error={touched.mailAggree && errors.mailAggree}
          handleOnChange={(e) => setFieldValue("mailAggree", e.target.checked)}
        />
        <div className="text-right">
          <button
            type="submit"
            className="btn btn-success rounded-bottom-right"
          >
            {t("Save")}
            <i className="fas fa-arrow-right ml-3" />
          </button>
        </div>
      </form>

      <hr />

      {user && isParentRoles && !isRrr ? (
        <ParentUserAccount
          user={user}
          companyPlants={companyPlants}
          handleNotify={handleOnParentUserAccountNofify}
        />
      ) : (
        user &&
        company &&
        !isRrr && (
          // <CompanyForm initialCompany={user?.companies[0]} />
          <div>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link ${tab === "company" ? "active" : ""} `}
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  onClick={(e) => changeTab(e, "company")}
                >
                  {t("Company")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${tab === "plants" ? "active" : ""}`}
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={(e) => changeTab(e, "plants")}
                >
                  {t("Plants")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${tab === "users" ? "active" : ""}`}
                  id="pills-user-tab"
                  data-toggle="pill"
                  href="#pills-user"
                  role="tab"
                  aria-controls="pills-user"
                  aria-selected="false"
                  onClick={(e) => changeTab(e, "users")}
                >
                  {t("Users")}
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className={`tab-pane fade ${
                  tab === "company" ? "show active" : ""
                }`}
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <CompanyForm initialCompany={company} isCompany />
              </div>
              <div
                className={`tab-pane fade ${
                  tab === "plants" ? "show active" : ""
                }`}
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                {company && (
                  <PlantsList
                    companyPlants={companyPlants}
                    materialGroups={company.polymers || []}
                    setCompanyPlants={setCompanyPlants}
                  />
                )}
              </div>
              <div
                className={`tab-pane fade ${
                  tab === "users" ? "show active" : ""
                }`}
                id="pills-user"
                role="tabpanel"
                aria-labelledby="pills-user-tab"
              >
                <ParentUserAccount
                  user={user}
                  companyPlants={companyPlants}
                  handleNotify={handleOnParentUserAccountNofify}
                />
              </div>
            </div>
          </div>
        )
      )}
    </>
  )
}

export default UserAccount2
