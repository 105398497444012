import { useState, useEffect } from "react"

import { UserRoles } from "../modules/users/types"
import { jwtDecode } from "../utils/jwt"
import useStateGetter from "./useStateGetter"

const useUserFromToken = () => {
  const [decodedToken, setDecodedToken] = useState({
    id: 0,
    email: "",
    name: "",
    exp: Infinity,
    role: 0,
    fieldsOfActivity: null,
    isAdmin: null,
    isRecycler: null,
    isRrr: null,
    isAuditor: null,
    isSectorAssociationRepresentative: null,
    isConverter: null,
    isRecyclerAndConverter: null,
    registrationFinished: null,
    isSubscriptionAggree: null,
  })
  const token = useStateGetter<string>(["auth", "accessToken"]) ?? ""

  useEffect(() => {
    const jwt = jwtDecode(token)

    if (jwt) {
      const { role } = jwt
      setDecodedToken({
        ...jwt,
        ...{
          isAdmin: role === UserRoles.ADMIN,
          isRecycler: role === UserRoles.RECYCLER,
          isRrr: role === UserRoles.RECOVINYL_REGIONAL_REPRESENTATIVE,
          isAuditor: role === UserRoles.AUDITOR,
          isSectorAssociationRepresentative:
            role === UserRoles.SECTOR_ASSOCIATION_REPRESENTATIVE,
          isConverter: role === UserRoles.CONVERTER,
          isRecyclerAndConverter: role === UserRoles.RECYCLER_AND_CONVERTER,
        },
      })
    }
  }, [token])
  return decodedToken
}

export default useUserFromToken
