import React, { useEffect, useState, FormEvent } from "react"
import { useRouteMatch, Link } from "react-router-dom"
import { handleToast } from "../../../utils/messages"
import { history } from "../../../utils/routes"
import { getNotification, putNotification } from "../service/api"
import NotificationForm, { NotificationFormData } from "../components/NotificationForm"
import { Notification } from "../types"

const NotificationEdit = () => {
  const { params }: { params: { id: string } } = useRouteMatch()
  const id = parseInt(params.id)
  const [notification, setNotification] = useState<Notification>()

  useEffect(() => {
    getNotification({ id }).then((note) => {
      const { data } = note
      if (data) {
        setNotification(data)
      }
    })
  }, [id])

  const handleSubmit = async (formData: NotificationFormData) => {
    const polymerIds = formData.polymers.map(o => o.value)
    const {subject, status, filePath, content, subscriptionBtn} = formData
    const { data, errors } = await putNotification({
      id,
      subject,
      status,
      filePath,
      content,
      polymers: polymerIds,
      subscriptionBtn
    })
    console.log(data)
    if (errors) {
      handleToast(errors, "error")
    } else {
      handleToast(["Notification updated"], "info")
      history.push('/notifications')
    }
  }

  return (
    <>
      <NotificationForm 
        submitHandler={handleSubmit} 
        notificationInit={notification}
      >        
      </NotificationForm>
    </>
  )
}

export default NotificationEdit
