import { restHelper } from "../../@common/service/api"
import { Notification, NotificationAudit } from "../types"

export interface PostNotificationsInput {
  title: string
  content: string
  polymers: number[]
  filePath: string,
  status: string,
  subscriptionBtn: boolean
}

export interface PutNotificationsInput extends PostNotificationsInput{
  id: number
}

export const queries = {
  notifications: `
    query {
      notifications {
        id 
        subject 
        content
        status
        polymers
        filePath
        subscriptionBtn
      }
    }
  `,
  notification: `
    query notification(
      $id: Int!
    ) {
      notification(
        id: $id
      ) {
        id
        subject
        content
        status
        polymers
        filePath
        subscriptionBtn
      }
    }
  `,
  notificationAudit: `
    query notificationAudit(
      $id: Int!
    ) {
      notificationAudit(
        id: $id
      ) {
        id
        acceptAt
        user {
            id
            email
            name
            lastActive
            lastActiveEmulate
            accepted
            loginAttempts
            blocked
            registrationFinished
        }
      }
    }
  `,
}

export const mutations = {
  notificationUpdate: `
    mutation updateNotification(
      $id: Int!
      $subject: String
      $content: String
      $status: String!
      $polymers: [Int]
      $filePath: String
      $subscriptionBtn: Boolean
    ) {
      updateNotification(
        id: $id
        subject: $subject
        content: $content
        polymers: $polymers
        status: $status
        filePath: $filePath
        subscriptionBtn: $subscriptionBtn
      ) {
        id
        subject
        content
        polymers
        status
        filePath
        subscriptionBtn
      }
    }  
  `,
  notificationCreate: `
    mutation createNotification(
      $subject: String!
      $content: String!
      $status: String!
      $polymers: [Int]
      $filePath: String
      $subscriptionBtn: Boolean
    ) {
      createNotification(
        subject: $subject
        content: $content
        polymers: $polymers
        status: $status
        filePath: $filePath
        subscriptionBtn: $subscriptionBtn
      ) {
        id
        subject
        content
        polymers
        status
        filePath
        subscriptionBtn
      }
    }  
  `,
}

export const getNotifications = restHelper<Notification[], object>(queries, "notifications")

export const getNotification = restHelper<Notification, { id: number }>(queries, "notification")

export const getNotificationAudit = restHelper<NotificationAudit[], { id: number }>(queries, "notificationAudit")

export const putNotification = restHelper<Notification, PutNotificationsInput>(mutations, "notificationUpdate")

export const postNotification = restHelper<Notification, PostNotificationsInput>(mutations, "notificationCreate")
