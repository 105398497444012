import { Company } from "../companies/types"

export enum UserRoles {
  ADMIN = 1,
  RECYCLER,
  RECOVINYL_REGIONAL_REPRESENTATIVE,
  AUDITOR,
  SECTOR_ASSOCIATION_REPRESENTATIVE,
  RECYCLER_AND_CONVERTER,
  CONVERTER,
}

export const USER_FIELDS_OF_ACTIVITY = [
  { value: 1, label: "Pipes" },
  { value: 2, label: "Flooring" },
  { value: 3, label: "Roofing/waterproofing" },
  { value: 4, label: "Profiles" },
  { value: 5, label: "Films and sheets" },
]

export interface User {
  id: string
  email: string
  name: string
  lastActive?: Date
  lastActiveEmulate?: Date
  accepted?: Date
  loginAttempts?: string
  blocked?: Date
  role: UserRoles
  companies: Company[]
  company?: Company
  plants?: Company[]
  children?: User[]
  companiesToAttach?: Company[]
  registrationFinished?: boolean
  password?: string
  canBeImpersonated?: boolean
  mailAggree?: Boolean
}

export interface CreateUserInput {
  email: string
  name: string
  companyId?: number
}

export interface UserIdInput {
  id: number
}

export interface AttachUserToCompanies {
  userId: number
  companyIds: number[]
}

export interface AttachUserToCompanyInput {
  userId: number
  companyId: number
}

export interface UserUpdateInput extends UserIdInput {
  name?: string
  email?: string
  password?: string
  company?: Company
  mailAggree?: boolean
}

export interface UserCompanies {
  [key: number]: Company[]
}

export interface PutUserInput extends User {
  password?: string
  company?: Company
}

export interface AcceptNotificationInput {
  id: number
}